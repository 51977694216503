import { Model as BaseModel } from "vue-api-query";

export default class Model extends BaseModel {
  // define a base url for a REST API
  baseURL() {
    //Below works for dynamic domain from window.location
    //let domain = window.location.hostname;
    // return 'http://api.' + domain + '/api/v1';
    return process.env.VUE_APP_MODEL_BASE_URL;
  }

  // implement a default request method
  request(config) {
    return this.$http.request(config);
  }

  setFilters(filters) {
    console.log("Filter length");
    console.log(Object.keys(filters).length);
    for (let [key, value] of Object.entries(filters)) {
      if (Array.isArray(value)) {
        //If key contains _id, then lets just grab the ID from the filters
        //when we appy them. Example...mailing_id when v-model is an array of
        //mailings attached to a multiselect
        if (key.includes("_id")) {
          value = value.map((v) => v.id);
        }
        this.whereIn(key, value);
      } else {
        this.where(key, value);
      }
    }

    return this;
  }
}
