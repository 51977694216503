<template>
  <div
    class="px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center"
  >
    <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
      <div class="text-center mb-5">
        <img :src="$appLogo" alt="Image" class="mb-3" style="max-width: 90%" />
        <div class="text-900 text-3xl font-medium mb-3">Accept Invite</div>
        <span class="text-600 font-medium line-height-3"
          >Already have an account?</span
        >
        <a
          class="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
          @click="this.$router.push({ name: 'login' })"
          >Login!</a
        >
      </div>

      <div v-if="showForm">
        <div class="p-field mb-4">
          <label for="nameInput" class="block text-900 font-medium mb-2"
            >Name</label
          >
          <InputText
            type="text"
            id="nameInput"
            class="p-inputtext p-component w-full p-3"
            :class="
              validationErrors && validationErrors.name ? 'p-invalid' : ''
            "
            v-model="form.name"
          />
          <small
            class="p-error"
            v-if="validationErrors && validationErrors.name"
          >
            {{ validationErrors.name[0] }}
          </small>
        </div>

        <div class="p-field mb-4">
          <label for="emailInput" class="block text-900 font-medium mb-2"
            >Email</label
          >
          <InputText
            type="email"
            id="emailInput"
            class="p-inputtext p-component w-full p-3"
            :class="
              validationErrors && validationErrors.email ? 'p-invalid' : ''
            "
            v-model="form.email"
          />
          <small
            class="p-error"
            v-if="validationErrors && validationErrors.email"
          >
            {{ validationErrors.email[0] }}
          </small>
        </div>

        <div class="p-field mb-3">
          <label for="passwordInput" class="block text-900 font-medium mb-2"
            >Password</label
          >
          <InputText
            type="password"
            id="passwordInput"
            class="p-inputtext p-component w-full p-3"
            :class="
              validationErrors && validationErrors.password ? 'p-invalid' : ''
            "
            v-model="form.password"
          />
          <small
            class="p-error"
            v-if="validationErrors && validationErrors.password"
          >
            {{ validationErrors.password[0] }}
          </small>
        </div>

        <div class="p-field mb-3">
          <label
            for="passwordConfirmationInput"
            class="block text-900 font-medium mb-2"
            >Password Confirmation</label
          >
          <InputText
            type="password"
            id="passwordConfirmationInput"
            class="p-inputtext p-component w-full p-3"
            :class="
              validationErrors && validationErrors.password_confirmation
                ? 'p-invalid'
                : ''
            "
            v-model="form.password_confirmation"
          />
          <small
            class="p-error"
            v-if="validationErrors && validationErrors.password_confirmation"
          >
            {{ validationErrors.password_confirmation[0] }}
          </small>
        </div>

        <Button
          label="ACCEPT INVITE"
          icon="pi pi-key"
          class="p-button p-component w-full p-3"
          @click.prevent="acceptInvite"
          :loading="loading"
        ></Button>
      </div>
      <div v-else>
        <div class="text-center mb-5">
          <h1>Invitation not found!</h1>
          <p>Please check your link and try again.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import UserInvite from "@/models/UserInvite";

export default {
  data() {
    return {
      token: this.$route.params.token,
      showForm: true,
      form: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        token: this.$route.params.token,
      },
      validationErrors: null,
      loading: false,
    };
  },
  async mounted() {
    await UserInvite.where("token", this.token)
      .first()
      .then((invite) => {
        if (invite.id) {
          this.showForm = true;
          this.form.name = invite.first_name;
          this.form.email = invite.email;
        } else {
          this.showForm = false;
        }
      });
  },
  methods: {
    acceptInvite() {
      // Set loading state
      this.loading = true;

      // Clear previous validation errors
      this.validationErrors = null;

      axios
        .post("/user-invites/accept", this.form)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Invite accepted. You can now login.",
            life: 4000,
          });

          this.$router.push({ name: "login" });
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.validationErrors = error.response.data.errors;

            if (this.validationErrors.token) {
              this.showErrorToast("Invite link may have expired.");
            }
          } else if (error.response && error.response.status === 401) {
            this.showErrorToast("Invite link may have expired.");
          } else {
            this.showErrorToast(
              "Something went wrong. Please try again later."
            );
          }
        })
        .then(() => (this.loading = false));
    },
    showErrorToast(message) {
      this.$toast.add({
        severity: "error",
        summary: "Error",
        detail: message,
        life: 5000,
      });
    },
  },
};
</script>

<style scoped>
#signInImage {
  background-image: url("~@/assets/signin.jpg");
}
</style>
