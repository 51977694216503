<template>
  <div class="min-h-screen flex flex-column bg-primary">
    <div class="bg-primary shadow-0 border-round-botom" style="height: 250px">
      <div
        class="py-3 px-5 flex align-items-center justify-content-between relative lg:static bg-white"
        style="min-height: 80px"
      >
        <img
          :src="$appLogo"
          alt="Image"
          height="50"
          class="mr-0 ml-2 lg:mr-6"
        />

        <a
          v-ripple
          class="cursor-pointer block lg:hidden text-indigo-50 p-ripple"
          v-styleclass="{
            selector: '@next',
            enterClass: 'hidden',
            enterActiveClass: 'scalein',
            leaveToClass: 'hidden',
            leaveActiveClass: 'fadeout',
            hideOnOutsideClick: true,
          }"
        >
          <i class="pi pi-bars text-4xl"></i>
        </a>
        <div
          class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1"
        >
          <ul
            class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row"
          ></ul>
          <ul
            class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 border-indigo-400 lg:border-top-none"
          >
            <!-- <li v-if="$can('clients.switch')">
              <a
                v-ripple
                @click="$router.push({ name: 'switch-clients' })"
                class="flex px-6 p-3 mr-4 lg:px-3 lg:py-2 align-items-center font-medium p-text-secondary border-round cursor-pointer"
              >
                <i class="pi pi-replay mr-2"></i>
                <span>{{ this.authStore.user.client.name }}</span>
              </a>
            </li> -->

            <li>
              <a
                v-ripple
                @click="logout"
                class="flex px-6 p-3 mr-4 lg:px-3 lg:py-2 align-items-center font-medium p-text-secondary border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
              >
                <i class="pi pi-sign-out mr-2"></i>
                <span>Logout</span>
              </a>
            </li>
            <!-- <li>
              <a
                v-ripple
                class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
              >
                <i class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0"></i>
                <span class="block lg:hidden font-medium">Notifications</span>
              </a>
            </li> -->
          </ul>
        </div>
      </div>
      <div class="align-items-center justify-content-between py-3 px-5">
        <MainMenu />
        <!-- <ul
          class="list-none py-3 px-5 m-0 flex align-items-center font-medium overflow-x-auto border-top-1 border-primary"
        >
          <li>
            <a
              v-ripple
              class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
              @click="$router.push({ name: 'dashboard' })"
            >
              <i class="pi pi-home mr-2"></i>
              <span>Dashboard</span>
            </a>
          </li>
          <li>
            <a
              class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
              v-styleclass="{
                selector: '#responders-drop',
                leaveToClass: 'hidden',
                toggleClass: 'hidden',
                hideOnOutsideClick: true,
              }"
            >
              <i class="pi pi-users mr-2"></i>
              <span>Contacts</span>
              <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
            </a>
            <ul
              id="responders-drop"
              class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:border-1 border-50 lg:shadow-2 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer"
            >
              <li>
                <a
                  class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150"
                  @click="$router.push({ name: 'responders' })"
                  v-styleclass="{
                    selector: '#responders-drop',
                    hideOnOutsideClick: true,
                    leaveToClass: 'hidden',
                    toggleClass: 'hidden',
                  }"
                >
                  <span class="font-medium">Responders</span>
                </a>
              </li>
              <li v-if="hasForms">
                <a
                  class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple"
                  @click="$router.push({ name: 'form-leads' })"
                  v-styleclass="{
                    selector: '#responders-drop',
                    hideOnOutsideClick: true,
                    toggleClass: 'hidden',
                  }"
                >
                  <span class="font-medium">Form Conversions</span>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a
              class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
              v-styleclass="{
                selector: '#manage-drop',
                leaveToClass: 'hidden',
                toggleClass: 'hidden',
                hideOnOutsideClick: true,
              }"
            >
              <i class="pi pi-cog mr-2"></i>
              <span>Manage</span>
              <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
            </a>
            <ul
              id="manage-drop"
              class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:border-1 border-50 lg:shadow-2 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-"pointer
            >
              <li>
                <a
                  class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150"
                  @click="toggleSwichClients"
                  v-styleclass="{
                    selector: '#manage-drop',
                    hideOnOutsideClick: true,
                    leaveToClass: 'hidden',
                    toggleClass: 'hidden',
                  }"
                >
                  <span class="font-medium">Toggle Switch Client</span>
                </a>
              </li>
            </ul>
          </li>
        </ul> -->
      </div>
    </div>

    <div
      class="py-2 md:px-5 flex flex-column flex-auto"
      style="margin-top: -5rem"
    >
      <div
        class="surface-border border-1 border-gray-200 md:border-round bg-gray-200 flex-auto p-text-secondary"
      >
        <Toast />
        <div id="mr_title" class="mb-5"></div>
        <router-view></router-view>
        <div id="mr_footer" class="mb-0"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { useAuthStore } from "./stores/auth";
import MainMenu from "@/components/MainMenu.vue";
import { useAppStore } from "./stores/app";
export default {
  setup() {
    const authStore = useAuthStore();
    const appStore = useAppStore();
    return { authStore, appStore };
  },
  components: {
    MainMenu,
  },
  name: "App.vue",
  methods: {
    logout() {
      this.authStore.signOut().then(() => {
        this.$router.push({ name: "login" });
      });
    },
    toggleSwichClients() {
      this.setSwitchClients(!this.shouldShowSwitchClients);
    },
  },
  computed: {
    hasForms() {
      return (
        this.appStore.currentCampaign &&
        this.appStore.currentCampaign.form_field_map &&
        JSON.parse(this.appStore.currentCampaign.form_field_map).length > 0
      );
    },
    hasMultipleCampaigns() {
      return (
        this.authStore.availableCampaigns &&
        this.authStore.availableCampaigns.length > 1
      );
    },
    shouldShowSwitchClients() {
      return false;
    },
  },
};
</script>
