<script>
import { useAuthStore } from "@/stores/auth";
export default {
  methods: {
    $can(permissionName) {
      const store = useAuthStore();
      let user = store.user;
      if (!user) return false;
      let userRoles = user.roles.map((role) => {
        return role.name;
      });
      let userPermissions = user.permissions.map((permission) => {
        return permission.name;
      });

      if (!permissionName) return true;
      // if no/blank permission was passed, assume we have permission
      else if (userRoles.indexOf("super-admin") !== -1) return true;
      else return userPermissions.indexOf(permissionName) !== -1;
    },
    $is(roleName) {
      const store = useAuthStore();
      let user = store.user;
      if (!user) return false;
      let userRoles = user.roles.map((role) => {
        return role.name;
      });

      if (!roleName) return true;
      // if no/blank permission was passed, assume we have permission
      else return userRoles.indexOf(roleName) !== -1;
    },
  },
};
</script>
