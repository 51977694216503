<template>
  <div
    class="px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center"
  >
    <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
      <div class="text-center mb-5">
        <img :src="$appLogo" alt="Image" class="mb-3" style="max-width: 90%" />
        <!-- <div class="text-900 text-3xl font-medium mb-3">Welcome Back</div> -->
      </div>

      <div>
        <div class="p-field mb-4">
          <label for="emailInput" class="block text-900 font-medium mb-2"
            >Email</label
          >
          <InputText
            type="email"
            id="emailInput"
            class="p-inputtext p-component w-full p-3"
            :class="
              validationErrors && validationErrors.email ? 'p-invalid' : ''
            "
            v-model="form.email"
          />
          <small
            class="p-error"
            v-if="validationErrors && validationErrors.email"
          >
            {{ validationErrors.email[0] }}
          </small>
        </div>

        <div class="p-field mb-3">
          <label for="passwordInput" class="block text-900 font-medium mb-2"
            >Password</label
          >
          <InputText
            type="password"
            id="passwordInput"
            class="p-inputtext p-component w-full p-3"
            :class="
              validationErrors && validationErrors.password ? 'p-invalid' : ''
            "
            v-model="form.password"
            v-on:keyup.enter="attemptLogin"
          />
          <small
            class="p-error"
            v-if="validationErrors && validationErrors.password"
          >
            {{ validationErrors.password[0] }}
          </small>
        </div>

        <div class="flex align-items-center justify-content-between mb-6">
          <span
            ><Checkbox v-model="rememberMe" :binary="true" /> Remember Me</span
          >
          <router-link to="/password-reset" class="no-underline"
            ><Button label="Forget Password?" class="p-button-text"
          /></router-link>
        </div>

        <Button
          label="Sign In"
          icon="pi pi-user"
          class="p-button p-component w-full p-3"
          @click.prevent="attemptLogin"
          :loading="loading"
        ></Button>
      </div>
    </div>
  </div>
</template>
<script>
import { useAuthStore } from "@/stores/auth";
import { useAppStore } from "@/stores/app";
export default {
  setup() {
    const authStore = useAuthStore();
    const appStore = useAppStore();

    return { authStore, appStore };
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      validationErrors: null,
      loading: false,
      rememberMe: false,
    };
  },
  methods: {
    async attemptLogin() {
      // Set loading state
      this.loading = true;

      // Clear previous validation errors
      this.validationErrors = null;

      await this.authStore
        .signIn(this.form)
        .then(() => {
          //console.log("res" + res);
          this.appStore.setCurrentColorScheme(this.authStore.user.colorScheme);
          if (this.rememberMe) {
            localStorage.rememberMe = this.form.email;
          } else if (
            localStorage.rememberMe &&
            localStorage.rememberMe !== ""
          ) {
            localStorage.rememberMe = "";
          }
          this.$router.push({ name: "dashboard" });
        })
        .catch((err) => {
          if (err.response && err.response.status === 422) {
            this.validationErrors = {
              email: err.response.data.errors.message,
            };
          } else {
            console.log(err.response.data);
            this.validationErrors = {
              email: ["Something went wrong. Please try again."],
            };
          }
        })
        .then(() => (this.loading = false));
    },
  },
  mounted() {
    if (localStorage.rememberMe && localStorage.rememberMe !== "") {
      this.form.email = localStorage.rememberMe;
      this.rememberMe = true;
    }
  },
};
</script>
