import { defineStore } from "pinia";

export const useAppStore = defineStore("app", {
  state: () => ({
    currentCampaign: null,
    colorScheme: null,
  }),
  persist: true,
  actions: {
    setCurrentCampaign(campaign) {
      this.currentCampaign = campaign;
    },
    setCurrentColorScheme(colorScheme) {
      console.log("setting colors");
      console.log(colorScheme);
      this.colorScheme = colorScheme;
    },
    resetState() {
      this.$reset();
    },
  },
});
