<template>
  <div
    class="px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center"
  >
    <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
      <div class="text-center mb-5">
        <img :src="$appLogo" alt="Image" style="max-width: 90%" class="mb-3" />
        <div class="text-900 text-3xl font-medium mb-3">
          Reset Your Password
        </div>
      </div>

      <div>
        <div class="p-field mb-4">
          <label for="emailInput" class="block text-900 font-medium mb-2"
            >Email</label
          >
          <InputText
            type="email"
            id="emailInput"
            class="p-inputtext p-component w-full p-3"
            :class="
              validationErrors && validationErrors.email ? 'p-invalid' : ''
            "
            v-model="form.email"
          />
          <small
            class="p-error"
            v-if="validationErrors && validationErrors.email"
          >
            {{ validationErrors.email[0] }}
          </small>
        </div>

        <div class="p-field mb-3">
          <label for="passwordInput" class="block text-900 font-medium mb-2"
            >New Password</label
          >
          <InputText
            type="password"
            id="passwordInput"
            class="p-inputtext p-component w-full p-3"
            :class="
              validationErrors && validationErrors.password ? 'p-invalid' : ''
            "
            v-model="form.password"
          />
          <small
            class="p-error"
            v-if="validationErrors && validationErrors.password"
          >
            {{ validationErrors.password[0] }}
          </small>
        </div>

        <div class="p-field mb-3">
          <label
            for="passwordConfirmationInput"
            class="block text-900 font-medium mb-2"
            >New Password Confirmation</label
          >
          <InputText
            type="password"
            id="passwordConfirmationInput"
            class="p-inputtext p-component w-full p-3"
            :class="
              validationErrors && validationErrors.password_confirmation
                ? 'p-invalid'
                : ''
            "
            v-model="form.password_confirmation"
            v-on:keyup.enter="resetPassword"
          />
          <small
            class="p-error"
            v-if="validationErrors && validationErrors.password_confirmation"
          >
            {{ validationErrors.password_confirmation[0] }}
          </small>
        </div>

        <div class="flex align-items-center justify-content-end mb-6">
          <router-link to="/login" class="no-underline"
            ><Button label="Go To Login" class="p-button-text"
          /></router-link>
        </div>

        <Button
          label="RESET PASSWORD"
          icon="pi pi-key"
          class="p-button p-component w-full p-3"
          @click.prevent="resetPassword"
          :loading="loading"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      token: this.$route.params.token,
      form: {
        email: "",
        password: "",
        password_confirmation: "",
        token: this.$route.params.token,
      },
      validationErrors: null,
      loading: false,
    };
  },
  mounted() {
    console.log(this.token);
  },
  methods: {
    resetPassword() {
      // Set loading state
      this.loading = true;

      // Clear previous validation errors
      this.validationErrors = null;

      axios
        .post("/auth/password/reset", this.form)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Password Reset Successful",
            detail: "Please login using your new credentials",
            life: 5000,
          });

          this.$router.push({ name: "login" });
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.validationErrors = error.response.data.errors;

            if (this.validationErrors.token) {
              this.showErrorToast("Password reset link may have expired.");
            }
          } else if (error.response && error.response.status === 401) {
            this.showErrorToast("Password reset link may have expired.");
          } else {
            this.showErrorToast(
              "Something went wrong. Please try again later."
            );
          }
        })
        .then(() => (this.loading = false));
    },
    showErrorToast(message) {
      this.$toast.add({
        severity: "error",
        summary: "Error",
        detail: message,
        life: 5000,
      });
    },
  },
};
</script>

<style scoped>
#signInImage {
  background-image: url("~@/assets/signin.jpg");
}
</style>
