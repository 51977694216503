<template>
  <Login v-if="$route.path === '/login'" />
  <PasswordReset v-else-if="$route.name === 'password-reset'" />
  <InviteAccept v-else-if="$route.name === 'accept-invite'" />
  <PasswordRequest v-else-if="$route.path === '/password-reset'" />
  <App v-else />

  <Toast />
</template>

<script>
import App from "./App.vue";
import Login from "./pages/Login.vue";
import PasswordRequest from "./pages/PasswordRequest";
import PasswordReset from "@/pages/PasswordReset";
import InviteAccept from "@/pages/InviteAccept";

export default {
  components: {
    App: App,
    Login: Login,
    PasswordRequest: PasswordRequest,
    PasswordReset,
    InviteAccept,
  },
};
</script>

<style lang="scss">
body {
  font-family: var(--font-family);
}
</style>
