<template>
  <div
    class="px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center"
  >
    <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
      <div class="text-center mb-5">
        <img :src="$appLogo" alt="Image" style="max-width: 90%" class="mb-3" />
        <div class="text-900 text-3xl font-medium mb-3">PASSWORD RESET</div>
      </div>

      <div>
        <div class="p-field mb-3">
          <label for="emailInput" class="block text-900 font-medium mb-2"
            >Email</label
          >
          <InputText
            type="email"
            id="emailInput"
            class="w-full p-inputtext p-component"
            :class="
              validationErrors && validationErrors.email ? 'p-invalid' : ''
            "
            v-model="form.email"
          />
          <small
            class="p-error"
            v-if="validationErrors && validationErrors.email"
          >
            {{ validationErrors.email[0] }}
          </small>
        </div>
        <Button
          label="RESET PASSWORD"
          class="w-full mt-2"
          :loading="loading"
          @click.prevent="resetPassword"
        ></Button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      form: {
        email: "",
      },
      validationErrors: null,
      loading: false,
    };
  },
  methods: {
    async resetPassword() {
      // Set loading state
      this.loading = true;

      // Clear previous validation errors
      this.validationErrors = null;

      await axios
        .post("/auth/password/request", this.form)
        .then(
          (res) => {
            // Clear input
            this.form.email = "";

            // Show success toast
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: res.data.message,
              life: 5000,
            });
          },
          (err) => {
            if (err.response && err.response.status === 422) {
              this.validationErrors = err.response.data.errors;
            } else {
              console.log(err.response.data);
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: "Something Went Wrong. Please Try Again",
                life: 2000,
              });
            }
          }
        )
        .catch((err) => {
          console.log("err2" + err);
        })
        .then(() => (this.loading = false));
    },
  },
};
</script>
