import { createApp } from "vue";
import AppWrapper from "./AppWrapper.vue";
import router from "./router/router.js";
import { createPinia } from "pinia";
import { createPersistedState } from "pinia-plugin-persistedstate";
import numeral from "numeral";

//CSS Imports for PrimeVue
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import PrimeVue from "primevue/config";
import StyleClass from "primevue/styleclass";
import Ripple from "primevue/ripple";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import DataTable from "primevue/datatable";
import OverlayPanel from "primevue/overlaypanel";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import Dialog from "primevue/dialog";
import Chart from "primevue/chart";
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import Calendar from "primevue/calendar";

import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";

import axios from "axios";

import Permissions from "./mixins/Permissions";
import Color from "./mixins/Color";
import { useAuthStore } from "./stores/auth";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

const pinia = createPinia();
pinia.use(
  createPersistedState({
    storage: {
      getItem: (key) => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key),
    },
    beforeRestore: () => {},
    afterRestore: () => {},
    serializer: {
      serialize: JSON.stringify,
      deserialize: JSON.parse,
    },
  })
);

axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const authStore = useAuthStore();
    if (error.response.status === 401) {
      authStore.resetState();
      if (!router.currentRoute.value.meta.requiresGuest) {
        router.push("/login");
      } else {
        console.log("No need to redirect");
      }
    }

    return Promise.reject(error);
  }
);

import { Model } from "vue-api-query";

//First set model
Model.$http = axios;
//Then set base URL for axios
axios.defaults.baseURL = process.env.VUE_APP_MODEL_BASE_URL;

//Check user on page load
const app = createApp(AppWrapper);
app.use(pinia);
app.use(router);
app.mixin(Permissions);
app.mixin(Color);
app.use(PrimeVue, { ripple: true });
app.use(ToastService);
app.component("Toast", Toast);
app.component("InputText", InputText);
app.component("Button", Button);
app.component("OverlayPanel", OverlayPanel);
app.component("MultiSelect", MultiSelect);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("Row", Row);
app.component("ColumnGroup", ColumnGroup);
app.component("Dialog", Dialog);
app.component("Checkbox", Checkbox);
app.component("Chart", Chart);
app.component("Dropdown", Dropdown);
app.component("Calendar", Calendar);
app.directive("styleclass", StyleClass);
app.directive("ripple", Ripple);
app.config.globalProperties.$appLogo = process.env.VUE_APP_LOGO;
app.config.globalProperties.$filters = {
  numberFourDecimal(value) {
    return numeral(value).format("0,0.0000");
  },
  numberTwoDecimal(value) {
    return numeral(value).format("0,0.00");
  },
  numberOneDecimal(value) {
    return numeral(value).format("0,0.0");
  },
  numberThousandSeparator(value) {
    return numeral(value).format("0,0");
  },
};

const authy = useAuthStore();

authy.me().then(() => {
  app.mount("#app");
});
